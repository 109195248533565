
export const content = {
    menu:{
        product:'穿越号',
        company:'公司简介',
        services:'服务支持',
        creatorCommunity:'开发者社区',
        download:'下载app',
        bookNow:'立即预约',
        inquireNow:'立即咨询',
        aboutUs:'关于我们',
        sdk:'开发者联盟',
        about:'联系我们',
        privacy:'隐私政策',
        userAgreementen:'用户协议',
    },
    home:{
        floor2:{
            name:'穿越号',
            description:'轻装出行时尚，精彩跃然镜上',
            btn1:'立即预约',
            btn2:'查看详细'
        }
    },
    through:{
        floor1:{
            name:["Future Glasses","穿越号"],
            description:"轻装出行时尚，精彩跃然镜上",
            btn:"立即预约"
        },
        floor2:{
            name:"超轻薄形态 时尚与舒适兼备",
            description:"打造零压感佩戴体验，满足长时间使用。",
            weight:"重量仅"
        },
        floor3:{
            name:"抬头玩手机，减轻颈椎的负荷",
            description:"低头玩手机会造成颈椎压力，穿越号将手机内容投射在200寸屏幕上， 让你抬头仍能用手机，从而减轻了颈椎负荷。"
        },
        floor4:{
            name:"小空间 也能装下大场面",
            description:"虚拟成像，使用不受空间大小限制。"
        },
        floor5:{
            name:"开启CR模式，虚实同步呈现",
            description:"虚拟和现实同时展示，行走观影两不误。"
        },
        floor6:{
            name:"超高亮度，户外使用更清晰",
            description:"700nit超高显示亮度，在阳光充足的室外也能看的清楚。"
        },
        floor7:{
            name:"支持3D显示带来全新的视觉冲击",
            description:"3D显示技术能够带来沉浸式的感官体验，让你身临其境、全身心融入专属你的电影、游戏、故事等。"
        },
        floor8:{
            name:["适配主流设备","随时扩展大屏"],
            description:"支持与外部设备的连接，如手机、游戏机等，进一步扩展了用户的使用场景和体验。"
        },
        floor9:{
            name:"携带无负担，满足多场景需求",
            description:"外形小巧，携带无负担，满足更多场景的使用需求，出差旅行，说走就走",
            img:["畅享超大屏幕，细节尽收眼底！","感受全场氛围，身临其境！","办公随时随地，工作高效给力！","真实互动，体验震撼快感！"]
        },
        floor10:{
            name:"保证使用过程私密性，随心畅玩",
            description:"所有内容仅在眼镜呈现，保护用户在使用过程中的私密性。"
        }
    },
    companyProfile: {
        floor1: {
            title: ['关于', '我们'],
            description: '我们是一家位于新加坡的新兴公司，致力于提供令人兴奋的各种体验和服务。基于人类的快乐感来源于意识，所以我们将设计一个被称之为元宇宙的平行世界。'
        },
        floor2: {
            title: ['企业', '宗旨'],
            description: [
                '我们用爱心和技术让人们在无限的元宇宙里。',
                '思想是自由的 — 心想事成',
                '隐私是保密的 — 独自享受',
                '生活是美好的 — 幸福快乐'
            ]
        },
        floor3: {
            title: ['首要', '任务'],
            description: '用独特的高科技将2D视频等升级成美好的3D大视频新时代，即用CR Vision，3D camera，FOS等赋能现实世界的各种人生体验。'
        },
        floor4: {
            title:['企业','使命'],
            description: '让人们在虚拟的平行世界里创造和分享实在的精神财富。',
            imgs:['Web.3','区块链','PGC','UGC','AIGC','短视频']
        },
        floor5: {
            title: ['元宇宙移民的','愿景'],
            description: '打造ID身份，建设美丽家园，压缩永存数据等让人类重返快乐永生的伊甸园。'
        }
    },
    servicesFeatures:{
        floor1: {
            img:''
        },
        floor2: {
            title: '定制化移民体验',
            description: "我们为每位客户提供独一无二的元宇宙移民体验，满足他们的个性化需求和梦想。无论是脚踏实地的安居乐业，还是异想天开的人生梦想，我们都能满足您的期望。"
        },
        floor3: {
            title: '技术创新',
            description: "我们采用最先进的虚拟现实技术和人工智能，为客户提供身临其境的元宇宙体验。我们与行业领先的技术合作伙伴紧密合作，确保我们的服务始终保持在技术前沿。"
        },
        floor4: {
            title: '社交互动',
            description: "我们鼓励社交互动，为客户提供虚拟社交机会。您可以与朋友、家人和全球社区在元宇宙中建立联系，共同探索虚拟世界。"
        },
        floor5: {
            title: '安全和隐私',
            description: "我们将客户的安全和隐私视为头等大事。我们采取严格的数据保护措施，确保客户在虚拟世界中的信息得到保护。"
        }
    },
    creatorCommunity: {
        floor1: {
            img: "",
            title: "UGC 共享",
            description: "每一位消费者都有机会在分享、广告、盲盒等项目中获取意外利益。"
        },
        floor2: {
            title: "",
            description: "这是一个充满创意和无限可能性的空间，让您能够共同构建和分享您的PGC（用户生成内容）作品。在这里，您可以将您的创意变为虚拟现实，与全球的元宇宙探险家们分享，探索无尽的创作机会。"
        },
        floor3: {
            img: ""
        },
        floor4: [
            {
                title: "创作与分享",
                description: "在我们的创作者社区中，您可以轻松上传、展示和分享您的PGC作品，无论它们是虚拟艺术品、场景、互动游戏还是其他创意内容。"
            },
            {
                title: "学习与协作",
                description: "与其他元宇宙创作者交流经验，共同学习技术和创作方法，并在合作项目中释放你的创造力，以实现更大的愿景。"
            },
            {
                title: "奖励和认可",
                description: "我们重视社区成员的贡献。基于您的作品和参与，您有机会获得奖励和认可，同时在元宇宙领域建立您的声誉。"
            },
            {
                title: "技术支持",
                description: "我们为社区成员提供技术支持和资源，帮助您实现自己的创造力，并确保您的PGC作品在Metaverse中获得最佳体验。"
            },
            {
                title: "全球互联",
                description: "与来自世界各地的元宇宙爱好者建立联系，探索不同的文化和创造力，并一起在元宇宙中展开全球冒险。"
            }
        ]
        ,
        floor5: {
            title: "我们鼓励每一位创作者，无论是业余爱好者还是专业创作者，都加入我们的社区，与我们一起塑造元宇宙的明天。",
            description: "无限创意的时代已经来临，一起创造未来吧！"
        },
    },
    ar: {
        floor1: {
            title: "连接未来系列眼镜和Future OS的桥梁",
            description: "Future AR是Futue OS的启动核心和用户管理和互动中心，以APP的形式展示。是启动FOS的一把钥匙。除此之外，Future AR还包含了3D空间，应用商店，商城。"
        },
        floor2: [
            {
                title: "应用商店",
                description: "提供所有元宇宙玩家的开发入口，支持U3D开发接口，提供给3D APP发烧友和专业3D开发团队将APP植入FOS未来应用管理主要是用来管理需要在未来智镜中运行的应用。需要在只见未来上运行的APP，必须通过未来应用管理实现下载、安装、运行"
            },
            {
                title: "3D空间",
                description: "提供原宇宙视频内容发烧友将3D视频内容，全景视频内容，或者高清视频内容上传到FOS，自己享受自己的大屏和3D作品。史无前例。"
            }
        ]
    },
    sdk:{
        floor1: {
            title: "适用于 FUTURE CROSSER + Android 手机的Unity SDK,为Unity开发者 提供 构建AR应用的工具",
            btn: "下载ARSDK"
        },
        floor2: {
            title: "提供以下能力",
            description: "SDK 是基于Google CardBoard",
            img:["双目立体渲染","FUTURE CROSSER头部追踪","在线语音识别","虚拟触控交互"]
        },
        floor3: {
            title: "使用 FUTURE CROSSER 屏幕的双目立体渲染能力，真实还原 3D 场景、物体的能力。",
            description: "基于 Google Cardboard SDK 与眼镜的适配。"
        },
        floor4: {
            title: "使用眼镜提供的 IMU传感器的数据，追踪使用者头部3Dof运动姿态的能力。",
            description: "基于眼镜的 IMU 传感器；基于传感器数据的姿态算法。"
        },
        floor5: {
            title: "FOS提供的在线语音解决方案，是为开发者和用户提供的语音交互方式。",
            description: "支持中文指令词基于语音识别库的识别能力基于Android设备的麦克风"
        },
        floor6: {
            title: "为开发者和用户提供的触控交互方式。",
            description: "戒指控制,连接戒指,通过虚拟射线进行控制手机触摸控制,手机的 3Dof 数据转换为 3D 空间内的射线进行控制提供 VRControllerInputModule提供事件、射线检测"
        }
    },
    appointment:{
        title:["Future Glass 穿越号","预留信息","到货我们将第一时间联系您"],
        text:["请输入邮箱号","请输入手机号","请输入收货人姓名","请输入收货地址"],
        btn:"提交",
        alertPanel:["您已成功预约","关闭窗口"]
    },
    enquireNow:{
        title:"如果您想了解更多产品信息，请留下您的联系方式。",
        text:[["姓名","请输入姓名"],["手机号","请输入手机号"],["咨询内容","请输入需要咨询的内容"]],
        btn:"提交"
    },
    about :{
        phone:"联系方式",
        email:"服务邮箱",
        address:"公司地址",
        shopAddress:"地址1",
        title:"我们的地点",
        officeAddress:"地址2"
    }
}