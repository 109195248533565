<template>
    <div class="trunk v-home">
        <div class="floor">
            <video muted="muted" poster="../assets/images/homes/poster.jpg" autoplay="autoplay" loop="loop">
                <source src="https://resen.hbcygc.net/wlzx/res/topvideo.mp4" type="video/mp4">
            </video>
            <div class="bottom-shadow"></div>
            <div class="font-head animate__animated">
                <div v-if="this.$parent.language == 'en'">
                    <p style="font-size: 4vw;font-family: 'hyyakuheiw';">Ignite Imagination <br />Launch the <span
                            style="color: #176dff;">FUTURE</span></p>
                    <p style="font-size: 1.5vw;">Next-generation smart glasses, with outstanding image quality and precise
                        manufacturing, set high standards for smart eyewear.</p>
                </div>
                <div v-if="this.$parent.language == 'zh'">
                    <p style="font-size: 4vw;font-family: 'douyuzhuiguang';">触发想象，启动未来</p>
                    <p style="font-size: 1.5vw;">次视代智能眼镜，以精彩画质与精密制程，打造智能眼镜高标准！</p>
                </div>
                <div class="arrowSS">
                    <img src="../assets/images/mouse.png" class="to-mouse">
                </div>
            </div>
        </div>
        <div class="floor f-switch" :style="{ backgroundImage: 'url(' + require('../assets/images/homes/floor2.png') + ')' }">
            <div class="f2-to1">
                <img class="single-product" src="../assets/images/homes/product2-1.png">
                <div class="single-text">
                    <p class="single-text-p1">Future Glass <span style="color: #4963AD;">{{$t('content.home.floor2.name')}}</span></p>
                    <p class="single-text-p2">{{$t('content.home.floor2.description')}}</p>
                </div>
                <div class="btns btns-product">
                    <router-link to="/Appointment?productId=b384edd2-7707-11ee-b86f-00163e2ab162"
                        class="c-btn c-btn-blue">{{$t('content.home.floor2.btn1')}}</router-link>
                    <router-link to="/Through" class="c-btn">{{$t('content.home.floor2.btn2')}}</router-link>
                </div>
            </div>
            <div class="f2-to2" v-if="showThroughBack == false"
                :style="{ backgroundImage: 'url(' + require('../assets/images/homes/product2-2.png') + ')' }">
            </div>
        </div>
    </div>
</template>
<style scoped lang="less">
@font-face {
  font-family: "douyuzhuiguang";
  src: url('./../font-familys/douyuzhuiguang.ttf')
}
.floor {
    color: white;

    video {
        width: 100%;
        position: relative;
        z-index: 0;
    }

    .bottom-shadow {
        bottom: -1px;
    }

    .font-head {
        position: absolute;
        left: 0;
        bottom: 4rem;
        z-index: 10;
        width: 100%;
        height: 20rem;
    }

    // .f-nothing {
    //     .d-img {
    //         position: absolute;
    //         z-index: 0;
    //         width: 100%;
    //         height: 100%;
    //         background-position: 50%;
    //         background-repeat: no-repeat;
    //         background-size: cover;
    //     }
    // }

    .f2-to1 {
        // border: 1px solid red;
    }

    .f2-to2 {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .single-product {
        // border: 1px solid red;
        width: 45vw;
        height: auto;
        margin-bottom: 3vh;
    }

    .single-text {
        color: #4C4C4C;
    }

    .single-text-p1 {
        font-size: 2.3vw;
        font-weight: bold;
    }

    .single-text-p2 {
        font-size: 3vw;
        font-weight: bold;
        width: 80vw;
        margin: 0 auto;
    }

    .btns {
        position: relative;
        z-index: 10;
        width: 100%;
        height: auto;
        padding-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1rem;

        .c-btn {
            border: 1px solid white;
            color: white;
            text-decoration: none;
        }

        .c-btn-blue {
            border: none;
            background-color: #4963AD;
        }

        .c-btn:hover {
            background-color: white;
            color: black;
        }
    }
}

.f-switch {
    background-color: antiquewhite;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<script>

export default {
    data() {
        return {
            showProductType: "blue",
            showThroughBack: true
        };
    },
    mounted() {
        window.addEventListener("load", (e) => {
            $(".font-head").eq(0).addClass("animate__bounceInUp");
        });
        window.addEventListener("scroll", (e) => { this.ergodic(); });
    },
    methods: {
        ergodic: function () {
            if ($(".v-home").length < 1)
                return;
            let _self = this;
            var screenHeight = window.screen.height;
            let hVal = screenHeight / 3;
            $.each($(".v-home").children(".floor"), function (index, item) {
                if (index == 0)
                    return true;
                if (index == 1) {
                    if (item.getBoundingClientRect().top < (screenHeight / 3)) {
                        $(item).find(".font-absolute").addClass("animate__bounceInUp");
                    }
                    if (item.getBoundingClientRect().top < -50) {
                        _self.showThroughBack = false;
                    }
                }
                else {
                    if (item.getBoundingClientRect().top < hVal) {
                        $(item).find(".font-absolute").addClass("animate__bounceInUp");
                    }
                }
            });
        },
        showProduct: function (type) {
            this.showProductType = type;
        }
    }
}
</script>